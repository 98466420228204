<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>代付订单</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchleft">
                            <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                                <el-form-item label="商户订单号">
                                    <el-input v-model="searchform.mchOrderNo" style="width: 200px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item v-if="user.user.roles[0].id == 1" label="商户id">
                                    <el-input v-model="searchform.mchId" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="交易号">
                                    <el-input v-model="searchform.claveRastreo" style="width: 180px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item v-if="user.user.nation != 'co'" label="收款账号">
                                    <el-input v-model="searchform.cuentaBeneficiario" style="width: 150px;" @keyup.enter.native="onSubmit" size="small"></el-input>
                                </el-form-item>
                                <el-form-item label="支付状态">
                                    <el-select v-model="searchform.status" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option label="放款中" value="1"></el-option>
                                        <el-option label="放款成功" value="2"></el-option>
                                        <el-option label="放款失败" value="3"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="通道" v-if="user.user.roles[0].id == 1">
                                    <el-select v-model="searchform.aisle" placeholder="请选择" style="width: 210px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option v-for="item in speiList" :key="item.type" :label="item.channelName" :value="item.type">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-if="user.user.nation != 'co'" label="放款类型">
                                    <el-select v-model="searchform.idens" placeholder="请选择" style="width: 110px;">
                                        <el-option label="不限" value=""></el-option>
                                        <el-option label="手动放款" value="手动放款"></el-option>
                                        <el-option label="接口放款" value="接口放款"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('time')">
                                    <el-date-picker
                                    v-model="value1"
                                    type="datetimerange"
                                    size="small"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item v-if="user.user.roles[0].id == 1" style="margin-top: 60;" label="国家" >
                                    <el-select v-model="searchform.nation" placeholder="请选择国家">
                                        <el-option :label="$t('buxian')" :value="null"></el-option>
                                        <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                                    <el-button size="small" type="primary" @click="download">导出</el-button>
                                    <el-button v-if="user.user.roles[0].id == 2 && user.user.nation != 'co'" size="small" type="primary" @click="manualLoan">手动放款</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="createTime1" label="创建时间" :width="160">
                            <!-- <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template> -->
                        </el-table-column>
                        <el-table-column prop="orgId" label="商户id" width="100px"></el-table-column>
                        <el-table-column prop="appId" label="账户" width="100px"></el-table-column>
                        <!-- <el-table-column prop="accountId" label="应用id"></el-table-column> -->
                        <el-table-column prop="claveRastreo" label="交易号"></el-table-column>
                        <el-table-column prop="mchOrderNo" label="商户订单号"></el-table-column>
                        <el-table-column prop="status" label="状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status==1" type="primary" size="mini">放款中</el-tag>
                                <el-tag v-if="scope.row.status==2" type="info" size="mini">放款成功</el-tag>
                                <el-tag v-if="scope.row.status==3" type="warning" size="mini" @click="openMessage(scope.row)" :title = scope.row.message>放款失败</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="monto" label="付款金额"></el-table-column>
                        <el-table-column prop="cost" label="服务费"></el-table-column>
                        <el-table-column prop="balance" label="扣除余额"></el-table-column>
                        <el-table-column prop="nombreBeneficiario" label="用户姓名"></el-table-column>
                        <el-table-column v-if="user.user.nation != 'co'" prop="cuentaBeneficiario" label="收款账号"></el-table-column>
                        <el-table-column prop="iden" label="放款类型"></el-table-column>
                        <el-table-column v-if="user.user.nation != 'co'" prop="bankName" label="银行名称"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1 && user.user.nation != 'co'" prop="channel" label="通道"></el-table-column>
                        <el-table-column v-if="user.user.nation != 'co'" prop="rpcUrl" label="查看凭证">
                            <template slot-scope="scope">
                                <el-link v-if="scope.row.status==2" :href="scope.row.rpcUrl" rel="external nofollow" type="primary" target="_blank">查看凭证</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1" prop="nation" label="国家" width="88"></el-table-column>
                        <el-table-column v-if="user.user.roles[0].id == 1  && user.user.nation != 'co'" prop="jobName" :label="$t('operator')" width="88">
                            <template slot-scope="scope">
                                <el-popconfirm title="确定驳回吗？" v-if="scope.row.status == 1"
                                    @confirm="cobrador(scope.row)" content="驳回通过">
                                    <el-button size="small" type="warning" icon="el-icon-check" circle slot="reference" plain></el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page">
                        <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                            @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
                    </div>
                </div>
            </div>
        </div>
        <pop-up title="放款" :isshow="showadd" :haveconfirm="true" width="25%" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="放款金额：" prop="money">
                        <el-input-number v-model="form.money" />
                    </el-form-item>
                    <el-form-item label="收款银行卡号：" prop="bankNo" :min="16" :max="18">
                        <el-input v-model="form.bankNo" />
                    </el-form-item>
                    <el-form-item label="收款名称：" prop="userName">
                        <el-input v-model="form.userName" />
                    </el-form-item>
                    <el-form-item label="开户行名称" prop="bankCode">
                        <el-select size="small" v-model="form.bankCode" filterable :placeholder="$t('qxz')" style="width:300px;">
                            <el-option :label="$t('qxz')" :value="null"></el-option>
                            <el-option v-for="item in accountlist"
                                :key="item.value"
                                :label="item.desc"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账户类型" prop="type">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option label="clabe" value="40"></el-option>
                            <el-option label="银行卡" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单号：" prop="orderNo">
                        <el-input v-model="form.orderNo" />
                    </el-form-item>
                    <el-form-item label="回调地址：" prop="notifyUrl">
                        <el-input v-model="form.notifyUrl" />
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    money: null,
                    bankNo: null,
                    orderNo: null,
                    userName: null,
                    bankCode: null,
                    notifyUrl: null,
                    type: null,
                },
                loading: false,
                showadd: false,
                list: [],
                searchform:{},
                accountlist: [],
                total: 1,
                pageNo: 1,
                pageSize: 10,
                value1: null,
                nation: null,
                nationlist: [],
                speiList:[],
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getaccount()
            this.getnation()
            this.getSpeiList()
        },
        methods: {
            openMessage(row) {
                // this.$alert(row.message, '放款失败信息', {
                // confirmButtonText: '确定',
                //     callback: action => {
                //         this.$message({
                //         type: 'info',
                //         message: `action: ${ action }`
                //         });
                //     }
                // });
                this.$alert('<strong>' + row.message + '</strong>', '放款失败信息', {
                    dangerouslyUseHTMLString: true
                });
            },
            getnation() {
                _api.get(apiurl.nationlist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.nationlist = newarr
                })
            },
            getSpeiList() {
                _api.get(apiurl.subChannelList, {
                    type: 1
                }).then(res => {
                    this.speiList = res.data
                })
            },
            getaccount() {
                _api.get(apiurl.bankType, {
                }).then(res => {
                    this.accountlist = res.data
                })
            },
            cancelar(row) {
                _api.get(apiurl.cancelarOrder, {
                    orderId: row.id,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            cobrador(row) {
                _api.get(apiurl.reject, {
                    audId: row.id,
                }).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('success'),
                        type: 'success'
                    });
                    this.getList()
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            download() {
                _api.download(apiurl.download, {
                    createTime: this.value1,
                    channel: this.searchform.channel,
                    orderNo: this.searchform.mchOrderNo,
                    claveRastreo: this.searchform.claveRastreo,
                    cuentaBeneficiario: this.searchform.cuentaBeneficiario,
                    status: this.searchform.status,
                    mchId: this.searchform.mchId,
                    aisle: this.searchform.aisle,
                    idens: this.searchform.idens,
                    nation: this.searchform.nation,
                }).then(res => {
                    let a = document.createElement('a');
                    let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                    let objectUrl = URL.createObjectURL(blob);
                    a.setAttribute("href", objectUrl);
                    a.setAttribute("download", '代付订单数据.xls');
                    a.click();
                })
            },
            manualLoan() {
                this.showadd=true;
            },
            getList() {
                this.loading = true
                _api.get(apiurl.order, {
                    page: this.pageNo,
                    size: this.pageSize,
                    createTime: this.value1,
                    orderNo: this.searchform.mchOrderNo,
                    claveRastreo: this.searchform.claveRastreo,
                    cuentaBeneficiario: this.searchform.cuentaBeneficiario,
                    status: this.searchform.status,
                    mchId: this.searchform.mchId,
                    aisle: this.searchform.aisle,
                    idens: this.searchform.idens,
                    nation: this.searchform.nation,
                }).then(res => {
                    this.loading = false
                    this.list = res.data.list
                    this.total = res.data.total
                })
            },
            getconfirm() {
                if(this.form.money == null || this.form.money == ""){
                    this.$message.error('金额不能小于1')
                    return
                }
                if(this.form.bankCode == null || this.form.bankCode == ""){
                    this.$message.error('银行编码不能为空')
                    return
                }
                if(this.form.bankNo == null || this.form.bankNo == ""){
                    this.$message.error('收款账号不能为空')
                    return
                }
                if(this.form.userName == null || this.form.userName == ""){
                    this.$message.error('收款名称不能为空')
                    return
                }
                if(this.form.type == null || this.form.type == ""){
                    this.$message.error('账户类型不能为空')
                    return
                }
                _api.post(apiurl.manualLoan, this.form).then(res => {
                    this.$notify.success({
                        title: this.$t('success'),
                        message: this.$t('success')
                    })
                    this.getList()
                    this.form = {
                        money: null,
                        bankNo: null,
                        orderNo: null,
                        userName: null,
                        bankCode: null,
                        notifyUrl: null,
                        type: null,
                    }
                    this.showadd = false
                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    })
                })
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            onSubmit() {
                this.pageNo = 1
                this.getList()
            },
            handchange(data) { //分页Size变化
                this.pageSize = data
                this.pageNo = 1
                this.getList()
            },
            currentchange(data) { //当前页变化
                this.pageNo = data
                this.getList()
            },
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>